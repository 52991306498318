<template>
    <div class="err">

        <h1 style="padding: 100px">404 NotFound
            <font style="font-size: 16px;padding-left: 50px">找不到该页面，即将回到首页</font>
        </h1>


    </div>
</template>
<script>
    import * as API from '@/utils/index.js'

    export default {
        data() {
            return {

            }
        },
        created() {

            setTimeout(() => {
                localStorage.clear(); //清空


                this.$router.push('/')
                // this.$router.go(-1)

            }, 3000)


        },
        methods: {

        }
    }
</script>
<style lang="scss" scoped>
    .err {
        background-color: #fff;
        // padding: 10px;
        height: 100vh;


    }
</style>